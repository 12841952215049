import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { ExcelOrderImport_warehouses_dd_multiComponent } from './ExcelOrderImport.warehouses_dd_multi.component'


interface Iapp_custom_dock_status_gridComponentEntity {
appointment_lookup?: string, item_lookup?: string, appointment_date?: string, appointment_status?: string, shipment_type?: string, dock_door?: string, check_in?: string, carrier_status?: string, csw_status?: string, expected_cases?: number, expected_full_lp?: number, picked_received_cases?: number, picked_full_lp?: number, loaded?: number, pick_received_percent?: number, warehouse?: string, project_lookup_code?: string, order_class?: string, length?: number, load_putaway_percent?: number, last_pick_received_user?: string, last_load_putaway_user?: string, shipment_id?: number, order_id?: number, item_type?: string, scheduled_arrival?: string, wait_time?: number, appointment_id?: number, order_type?: string, actual?: number, first_completed_pick_task?: number, last_completed_pick_task?: number}

interface Iapp_custom_dock_status_gridComponentInParams {
  warehouse_ids?: number[]}


class app_custom_dock_status_gridComponentRowModel extends GridRowModel {
  grid: app_custom_dock_status_gridComponent;
  entity: Iapp_custom_dock_status_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    appointment_lookup: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    order_class: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    project_lookup_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    item_lookup: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    scheduled_arrival: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    checked_in: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    carrier_status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    wait_time: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'number', '0.00')
,
null
      ),
    pick_receive_percent: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'number', '0.00')
,
null
      ),
    load_putaway_percent: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'number', '0.00')
,
null
      ),
    expected_cases: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    expected_full_lp: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    last_pick_received_user: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    last_load_putaway_user: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    length: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    actual: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'number', '0.00')
,
null
      ),
    dock_ddoor: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: app_custom_dock_status_gridComponent, entity: Iapp_custom_dock_status_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.shipment_id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: app_custom_dock_status_gridComponent, entity?: Iapp_custom_dock_status_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.shipment_id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.shipment_id;
    const inParams = {
      $keys:[$resultKey],
      from_date:  $grid.filters.from_date.control.value ,
      to_date:  $grid.filters.to_date.control.value ,
      warehouse_ids:  $grid.filters.warehouse.control.value ,
    };
    const data = await this.datasources.app.custom_ds_dock_appointments_dashboard_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.appointment_lookup.displayControl as TextModel).text = $row?.entity?.appointment_lookup;
    (this.cells.order_class.displayControl as TextModel).text = $row?.entity?.order_class;
    (this.cells.project_lookup_code.displayControl as TextModel).text = $row?.entity?.project_lookup_code;
    (this.cells.item_lookup.displayControl as TextModel).text = $row?.entity?.item_lookup;
    (this.cells.carrier_status.displayControl as TextModel).text = $row?.entity?.carrier_status;
    (this.cells.wait_time.displayControl as TextModel).text = $row?.entity?.wait_time.toString();
    (this.cells.pick_receive_percent.displayControl as TextModel).text = $row?.entity?.pick_received_percent?.toString();
    (this.cells.load_putaway_percent.displayControl as TextModel).text = $row?.entity?.load_putaway_percent?.toString();
    (this.cells.expected_cases.displayControl as TextModel).text = $row?.entity?.expected_cases?.toString();
    (this.cells.expected_full_lp.displayControl as TextModel).text = $row?.entity?.expected_full_lp?.toString();
    (this.cells.last_pick_received_user.displayControl as TextModel).text = $row?.entity?.last_pick_received_user;
    (this.cells.last_load_putaway_user.displayControl as TextModel).text = $row?.entity?.last_load_putaway_user;
    (this.cells.actual.displayControl as TextModel).text = $row.entity.actual.toString();
    (this.cells.dock_ddoor.displayControl as TextModel).text = $row?.entity?.dock_door;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'appointment_lookup' : {
        this.on_appointment_clicked();
        break;
      }
      case 'item_lookup' : {
        this.on_item_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: app_custom_dock_status_gridComponentRowModel,
  $grid: app_custom_dock_status_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  if ($utils.isDefined($row?.entity?.order_id)) {
  
      if ($row.entity?.item_type.toUpperCase() === 'SHIPMENT') {
          let predictions = (await $datasources.EndeavorLabs.ds_prediction_flow_order.get({ OrderId: $row?.entity?.order_id })).result;
  
          if ($utils.isDefined(predictions)) {
  
              $row.cells.length.displayControl.text = predictions.Predict.toFixed(1)
  
          }
      }
  
      else if ($row.entity?.item_type.toUpperCase() === 'ORDER') {
          let predictions = (await $datasources.EndeavorLabs.ds_prediction_flow_order.get({ OrderId: $row?.entity?.order_id })).result;
  
          if ($utils.isDefined(predictions)) {
  
              $row.cells.length.displayControl.text = predictions.Predict.toFixed(1)
  
          }
      }
  
  
      else if ($row.entity?.item_type.toUpperCase() === 'LOAD CONTAINER') {
          let predictions = (await $datasources.custom_ds_prediction_flow_load_container.get({ load_container_id: $row?.entity?.order_id })).result;
  
          if ($utils.isDefined(predictions)) {
  
              $row.cells.length.displayControl.text = predictions.Predict.toFixed(1)
  
          }
      }
  
  }
  
  
  
  
  if ($utils.isDefined($row.entity?.csw_status?.toUpperCase())) {
      let status_color: string = $row.entity?.csw_status?.toUpperCase()
  
      switch (status_color) {
          case 'GREEN':
              $row.cells.wait_time.styles.setStyle('background-color', 'rgba(0, 255, 0, 1)');
              break
          case 'RED':
              $row.cells.wait_time.styles.setStyle('background-color', 'rgba(255, 0, 0, 1)');
              break
          case 'WHITE':
              $row.cells.wait_time.styles.resetStyle()
              break;
          default:
              $row.cells.wait_time.styles.resetStyle()
              break;
      }
  }
  
  
  if ($utils.isDefined($row.entity?.carrier_status?.toUpperCase())) {
      let status_color: string = $row.entity?.carrier_status?.toUpperCase()
  
      switch (status_color) {
          case 'ON TIME':
              $row.cells.carrier_status.styles.setStyle('background-color', 'rgba(0, 255, 0, 1)');
              break
          case 'LATE':
              $row.cells.carrier_status.styles.setStyle('background-color', 'rgba(255, 0, 0, 1)');
              break
          default:
              $row.cells.carrier_status.styles.resetStyle()
              break;
      }
  }
  
  
  if ($utils.isDefined($row.entity?.check_in)) {
  
      $row.cells.checked_in.displayControl.text = convertUtcToLocalIso($row.entity?.check_in)
  }
  
  if ($utils.isDefined($row.entity?.scheduled_arrival)) {
      $row.cells.scheduled_arrival.displayControl.text = convertUtcToLocalIso($row.entity?.scheduled_arrival)
  }
  
  //Function
  
  function convertUtcToLocalIso(utcDateString: string): string {
      // Create a new Date object with a UTC date
      const utcDate = new Date(utcDateString);
  
      // Convert the UTC date to local time using toLocaleString and then parse it back to a Date object
      const localDate = new Date(utcDate.toLocaleString());
  
      // Manually format the local date to ISO format
      const year = localDate.getFullYear();
      const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(localDate.getDate()).padStart(2, '0');
      const hours = String(localDate.getHours()).padStart(2, '0');
      const minutes = String(localDate.getMinutes()).padStart(2, '0');
      const seconds = String(localDate.getSeconds()).padStart(2, '0');
  
      // Combine the components into ISO format
      const isoLocalDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
      return isoLocalDate;
  }
  
  /*
  if ($utils.isDefined($row.entity?.check_in)) {
  const timestamp = $row?.entity?.check_in
  const date = new Date(timestamp);
  const formattedDate = date.toISOString().slice(0, 10); // yyyy-mm-dd
  const formattedTime = date.toTimeString().slice(0, 8); // hh:mm:ss
  const result = `${formattedDate} ${formattedTime}`;
  
  $row.cells.checked_in.displayControl.text = result
  }
  
  
  if ($utils.isDefined($row.entity?.scheduled_arrival)) {
  const timestamp = $row?.entity?.scheduled_arrival
  const date = new Date(timestamp);
  const formattedDate = date.toISOString().slice(0, 10); // yyyy-mm-dd
  const formattedTime = date.toTimeString().slice(0, 8); // hh:mm:ss
  const result = `${formattedDate} ${formattedTime}`;
  
  $row.cells.scheduled_arrival.displayControl.text = result
  }
  
  */
  
  
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $row: app_custom_dock_status_gridComponentRowModel,
  $grid: app_custom_dock_status_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  if ($utils.isDefined($row.entity?.appointment_id)) {
      $shell.FootPrintManager.opendock_appointment_editor({ dockAppointmentId: $row.entity?.appointment_id });
  }
  
  
  
  }
  on_item_clicked(event = null) {
    return this.on_item_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_item_clickedInternal(
    $row: app_custom_dock_status_gridComponentRowModel,
  $grid: app_custom_dock_status_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  if ($utils.isAllDefined($row.entity?.item_type,$row.entity?.order_type )) {
  
  if($row.entity?.item_type.toUpperCase() === 'SHIPMENT' && $row.entity?.order_type.toUpperCase() === 'INBOUND')
  {  $shell.FootPrintManager.openpurchase_order_editor({ orderId: $row.entity?.order_id });}
  
  
  if($row.entity?.item_type.toUpperCase() === 'SHIPMENT' && $row.entity?.order_type.toUpperCase() === 'OUTBOUND')
  {  $shell.FootPrintManager.opensales_order_editor({ orderId: $row.entity?.order_id });}
  
  if($row.entity?.item_type.toUpperCase() === 'ORDER' && $row.entity?.order_type.toUpperCase() === 'INBOUND')
  {  $shell.FootPrintManager.openpurchase_order_editor({ orderId: $row.entity?.order_id });}
  
  
  if($row.entity?.item_type.toUpperCase() === 'ORDER' && $row.entity?.order_type.toUpperCase() === 'OUTBOUND')
  {  $shell.FootPrintManager.opensales_order_editor({ orderId: $row.entity?.order_id });}
  
  if($row.entity?.item_type.toUpperCase() === 'LOAD CONTAINER' && $row.entity?.order_type.toUpperCase() === 'INBOUND')
  {  $shell.FootPrintManager.openload_container_editor({ loadContainerId: $row.entity?.order_id });}
  
  
  if($row.entity?.item_type.toUpperCase() === 'LOAD CONTAINER' && $row.entity?.order_type.toUpperCase() === 'OUTBOUND')
  {  $shell.FootPrintManager.openload_container_editor({ loadContainerId: $row.entity?.order_id });}
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => ExcelOrderImport_warehouses_dd_multiComponent),
  ],
  selector: 'app-custom_dock_status_grid',
  templateUrl: './app.custom_dock_status_grid.component.html'
})
export class app_custom_dock_status_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: Iapp_custom_dock_status_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['relaxed','fit-content-table']);


  inParams: Iapp_custom_dock_status_gridComponentInParams = { warehouse_ids: [] };


  //#region Variables
  vars: { interval_id?: any } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     appointment_lookup: new GridHeaderModel(new HeaderStyles(null, null), 'Appointment lookup', false, false, null),       order_class: new GridHeaderModel(new HeaderStyles(null, null), 'Order class', false, false, null),       project_lookup_code: new GridHeaderModel(new HeaderStyles(null, null), 'Project lookup', false, false, null),       item_lookup: new GridHeaderModel(new HeaderStyles(null, null), 'Item lookup', false, false, null),       scheduled_arrival: new GridHeaderModel(new HeaderStyles(null, null), 'Scheduled arrival', false, false, null),       checked_in: new GridHeaderModel(new HeaderStyles(null, null), 'Checked in', false, false, null),       carrier_status: new GridHeaderModel(new HeaderStyles(null, null), 'Carrier status', false, false, null),       wait_time: new GridHeaderModel(new HeaderStyles(null, null), 'Wait time', false, false, null),       pick_receive_percent: new GridHeaderModel(new HeaderStyles(null, null), 'Pick % / Received %', false, false, null),       load_putaway_percent: new GridHeaderModel(new HeaderStyles(null, null), 'Load %/ Putaway %', false, false, null),       expected_cases: new GridHeaderModel(new HeaderStyles(null, null), 'Expected cases', false, false, null),       expected_full_lp: new GridHeaderModel(new HeaderStyles(null, null), 'Expected full LP', false, false, null),       last_pick_received_user: new GridHeaderModel(new HeaderStyles(null, null), 'Last Pick / Receive User', false, false, null),       last_load_putaway_user: new GridHeaderModel(new HeaderStyles(null, null), 'Last Load / Putaway User', false, false, null),       length: new GridHeaderModel(new HeaderStyles(null, null), 'Length', false, false, null),       actual: new GridHeaderModel(new HeaderStyles(null, null), 'Actual', false, false, null),       dock_ddoor: new GridHeaderModel(new HeaderStyles(null, null), 'Dock door', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: app_custom_dock_status_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('warehouse_ids') set $inParams_warehouse_ids(value: any) {
    this.inParams['warehouse_ids'] = value;
  }
  get $inParams_warehouse_ids(): any {
    return this.inParams['warehouse_ids'] ;
  }

  topToolbar = {
      refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, 'Refresh', 'ms-Icon ms-Icon--Refresh')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    from_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    to_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    from_date: new FieldModel(new DateBoxModel(this.formGroup.controls['from_date'] as DatexFormControl, null, false, '', 'datetime')
, new ControlContainerStyles(null, null), 'From date', false)
,
    to_date: new FieldModel(new DateBoxModel(this.formGroup.controls['to_date'] as DatexFormControl, null, false, '', 'datetime')
, new ControlContainerStyles(null, null), 'To date', false)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Dock Status Grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      from_date:  $grid.filters.from_date.control.value ,
      to_date:  $grid.filters.to_date.control.value ,
      warehouse_ids:  $grid.filters.warehouse.control.value ,
    };
    try {
    const data = await this.datasources.app.custom_ds_dock_appointments_dashboard_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new app_custom_dock_status_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: app_custom_dock_status_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const current_date = new Date(); //"now"
  
  
  $grid.filters.from_date.control.value =  addHours(current_date, -12)
  $grid.filters.to_date.control.value =  addHours(current_date, 48)
  
  
  //functions
  function addHours(date, hours) {
    const newDate = new Date(date.getTime());
    const hoursToAdd = hours * 60 * 60 * 1000;
    newDate.setTime(newDate.getTime() + hoursToAdd);
    return newDate;
  }
  
  //refresh the grid
  //setInterval($grid.refresh(), 10000);
  
  setInterval(() => $grid.refresh(), 300000) 
  
  
  }
  on_refresh_clicked(event = null) {
    return this.on_refresh_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refresh_clickedInternal(
    $grid: app_custom_dock_status_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
