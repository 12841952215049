import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_bol_shipping_lp_container_detailsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, GrossWeight?: number, NetWeight?: number, ExpectedPackagedPack?: { Id?: number, ShortName?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string, ShippingContainer?: { Id?: number, LookupCode?: string } }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { LookupCode?: string } }, Material?: { Id?: number, Description?: string, LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_bol_shipping_lp_container_details/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, GrossWeight?: number, NetWeight?: number, ExpectedPackagedPack?: { Id?: number, ShortName?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string, ShippingContainer?: { Id?: number, LookupCode?: string } }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { LookupCode?: string } }, Material?: { Id?: number, Description?: string, LookupCode?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_bol_shipping_lp_container_details/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, GrossWeight?: number, NetWeight?: number, ExpectedPackagedPack?: { Id?: number, ShortName?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string, ShippingContainer?: { Id?: number, LookupCode?: string } }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { LookupCode?: string } }, Material?: { Id?: number, Description?: string, LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_bol_shipping_lp_container_details/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
