import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { Reports_reports_homeComponent } from './Reports.reports_home.component';
import { Reports_custom_bol_reportComponent } from './Reports.custom_bol_report.component';
import { Reports_custom_dock_appointment_reportComponent } from './Reports.custom_dock_appointment_report.component';
import { Reports_custom_pick_slip_by_orderId_reportComponent } from './Reports.custom_pick_slip_by_orderId_report.component';
import { Reports_custom_pick_slip_by_wave_id_reportComponent } from './Reports.custom_pick_slip_by_wave_id_report.component';


@Injectable({ providedIn: 'root' })
export class Reports_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
  ) {
    super(dialog, toastr);
  }

  public Reports: Reports_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openreports_home(replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'reports_home');
    ShellService.openViewRequest$.next(
      {
        title: 'Reports',
        referenceName: 'Reports_reports_home',
        component: Reports_reports_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openreports_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'reports_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Reports_reports_homeComponent,
      'Reports',
      mode,
      dialogSize
    )
  }
  public opencustom_bol_report(inParams:{ orderId: number, shipmentId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'custom_bol_report',
        referenceName: 'Reports_custom_bol_report',
        component: Reports_custom_bol_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_bol_reportDialog(
    inParams:{ orderId: number, shipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_bol_reportComponent,
      'custom_bol_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_dock_appointment_report(inParams:{ dock_appointment_id?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock appointment report',
        referenceName: 'Reports_custom_dock_appointment_report',
        component: Reports_custom_dock_appointment_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_dock_appointment_reportDialog(
    inParams:{ dock_appointment_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_dock_appointment_reportComponent,
      'Dock appointment report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_pick_slip_by_orderId_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Custom Pick Slip',
        referenceName: 'Reports_custom_pick_slip_by_orderId_report',
        component: Reports_custom_pick_slip_by_orderId_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_pick_slip_by_orderId_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_pick_slip_by_orderId_reportComponent,
      'Custom Pick Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_pick_slip_by_wave_id_report(inParams:{ waveId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Custom Pick Slip',
        referenceName: 'Reports_custom_pick_slip_by_wave_id_report',
        component: Reports_custom_pick_slip_by_wave_id_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_pick_slip_by_wave_id_reportDialog(
    inParams:{ waveId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_pick_slip_by_wave_id_reportComponent,
      'Custom Pick Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Reports_reports_home') {
      this.logger.log('Reports', 'reports_home');
      const title = 'Reports';
      const component = Reports_reports_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_bol_report') {
      const title = 'custom_bol_report';
      const component = Reports_custom_bol_reportComponent;
      const inParams:{ orderId: number, shipmentId?: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_dock_appointment_report') {
      const title = 'Dock appointment report';
      const component = Reports_custom_dock_appointment_reportComponent;
      const inParams:{ dock_appointment_id?: number } = { dock_appointment_id: null };
      if (!isNil(params.get('dock_appointment_id'))) {
        const paramValueString = params.get('dock_appointment_id');
        inParams.dock_appointment_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_pick_slip_by_orderId_report') {
      const title = 'Custom Pick Slip';
      const component = Reports_custom_pick_slip_by_orderId_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_pick_slip_by_wave_id_report') {
      const title = 'Custom Pick Slip';
      const component = Reports_custom_pick_slip_by_wave_id_reportComponent;
      const inParams:{ waveId: number } = { waveId: null };
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    return result;
  }
}
