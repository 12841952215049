<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded " class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="ingress" let-tool>
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.all_in.readOnly"
                            [ngStyle]="tool.control.buttons.all_in.styles.style"
                            [ngClass]="tool.control.buttons.all_in.styles.classes"
                            (click)="all_in($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.all_in.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.all_in.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.all_in.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_940.readOnly"
                            [ngStyle]="tool.control.buttons.map_940.styles.style"
                            [ngClass]="tool.control.buttons.map_940.styles.classes"
                            (click)="on_940_map($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_940.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_940.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_940.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_943.readOnly"
                            [ngStyle]="tool.control.buttons.map_943.styles.style"
                            [ngClass]="tool.control.buttons.map_943.styles.classes"
                            (click)="on_943_map($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_943.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_943.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_943.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_947_in.readOnly"
                            [ngStyle]="tool.control.buttons.map_947_in.styles.style"
                            [ngClass]="tool.control.buttons.map_947_in.styles.classes"
                            (click)="under_development($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_947_in.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_947_in.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_947_in.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.query_poller.readOnly"
                            [ngStyle]="tool.control.buttons.query_poller.styles.style"
                            [ngClass]="tool.control.buttons.query_poller.styles.classes"
                            (click)="query_poller($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.query_poller.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.query_poller.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.query_poller.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_orders.readOnly"
                            [ngStyle]="tool.control.buttons.map_orders.styles.style"
                            [ngClass]="tool.control.buttons.map_orders.styles.classes"
                            (click)="map_imported_orders($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_orders.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_orders.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_orders.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.submit_orders.readOnly"
                            [ngStyle]="tool.control.buttons.submit_orders.styles.style"
                            [ngClass]="tool.control.buttons.submit_orders.styles.classes"
                            (click)="submit_orders_footprint($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.submit_orders.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.submit_orders.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.submit_orders.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.confirm_orders.readOnly"
                            [ngStyle]="tool.control.buttons.confirm_orders.styles.style"
                            [ngClass]="tool.control.buttons.confirm_orders.styles.classes"
                            (click)="confirm_orders($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.confirm_orders.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.confirm_orders.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.confirm_orders.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.check_order_status.readOnly"
                            [ngStyle]="tool.control.buttons.check_order_status.styles.style"
                            [ngClass]="tool.control.buttons.check_order_status.styles.classes"
                            (click)="check_order_status($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.check_order_status.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.check_order_status.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.check_order_status.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.insert_transaction.readOnly"
                            [ngStyle]="tool.control.buttons.insert_transaction.styles.style"
                            [ngClass]="tool.control.buttons.insert_transaction.styles.classes"
                            (click)="insert_transaction($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.insert_transaction.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.insert_transaction.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.insert_transaction.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
            </ng-template>
            <ng-template toolbarToolDef="egress" let-tool>
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.all_out.readOnly"
                            [ngStyle]="tool.control.buttons.all_out.styles.style"
                            [ngClass]="tool.control.buttons.all_out.styles.classes"
                            (click)="all_out($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.all_out.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.all_out.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.all_out.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_945.readOnly"
                            [ngStyle]="tool.control.buttons.map_945.styles.style"
                            [ngClass]="tool.control.buttons.map_945.styles.classes"
                            (click)="on_945_map($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_945.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_945.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_945.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_944.readOnly"
                            [ngStyle]="tool.control.buttons.map_944.styles.style"
                            [ngClass]="tool.control.buttons.map_944.styles.classes"
                            (click)="on_944_map($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_944.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_944.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_944.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_846.readOnly"
                            [ngStyle]="tool.control.buttons.map_846.styles.style"
                            [ngClass]="tool.control.buttons.map_846.styles.classes"
                            (click)="under_development($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_846.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_846.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_846.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_947.readOnly"
                            [ngStyle]="tool.control.buttons.map_947.styles.style"
                            [ngClass]="tool.control.buttons.map_947.styles.classes"
                            (click)="under_development($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_947.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_947.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_947.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.generate_846.readOnly"
                            [ngStyle]="tool.control.buttons.generate_846.styles.style"
                            [ngClass]="tool.control.buttons.generate_846.styles.classes"
                            (click)="under_development($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.generate_846.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.generate_846.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.generate_846.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.generate_947.readOnly"
                            [ngStyle]="tool.control.buttons.generate_947.styles.style"
                            [ngClass]="tool.control.buttons.generate_947.styles.classes"
                            (click)="under_development($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.generate_947.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.generate_947.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.generate_947.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.query_footprint.readOnly"
                            [ngStyle]="tool.control.buttons.query_footprint.styles.style"
                            [ngClass]="tool.control.buttons.query_footprint.styles.classes"
                            (click)="query_footprint($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.query_footprint.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.query_footprint.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.query_footprint.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_orders.readOnly"
                            [ngStyle]="tool.control.buttons.map_orders.styles.style"
                            [ngClass]="tool.control.buttons.map_orders.styles.classes"
                            (click)="map_exported_orders($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_orders.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_orders.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_orders.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.submit_orders.readOnly"
                            [ngStyle]="tool.control.buttons.submit_orders.styles.style"
                            [ngClass]="tool.control.buttons.submit_orders.styles.classes"
                            (click)="submit_orders_orderful($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.submit_orders.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.submit_orders.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.submit_orders.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
            </ng-template>
            <ng-template toolbarToolDef="test_map" let-tool>
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.test_940.readOnly"
                            [ngStyle]="tool.control.buttons.test_940.styles.style"
                            [ngClass]="tool.control.buttons.test_940.styles.classes"
                            (click)="on_940_test($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.test_940.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.test_940.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.test_940.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.test_945.readOnly"
                            [ngStyle]="tool.control.buttons.test_945.styles.style"
                            [ngClass]="tool.control.buttons.test_945.styles.classes"
                            (click)="on_945_test($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.test_945.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.test_945.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.test_945.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.test_943.readOnly"
                            [ngStyle]="tool.control.buttons.test_943.styles.style"
                            [ngClass]="tool.control.buttons.test_943.styles.classes"
                            (click)="on_943_test($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.test_943.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.test_943.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.test_943.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.test_944.readOnly"
                            [ngStyle]="tool.control.buttons.test_944.styles.style"
                            [ngClass]="tool.control.buttons.test_944.styles.classes"
                            (click)="on_944_test($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.test_944.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.test_944.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.test_944.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-code_box"
                     *ngIf="!fieldsets.code_box.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.code_box.collapsible }">
                    <div *ngIf="!fieldsets.code_box.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.code_box.toggle()">
                      <span class="fieldsetsTitle-text">Code box</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.code_box.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.code_box.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.code_box.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.code_box.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-runs" *ngIf="!fields.runs.hidden" 
                            class="field-container standard {{fields.runs.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.runs.styles.style"
                            [ngClass]="fields.runs.styles.classes">
                        <div class="label-container"
                              title="{{fields.runs.label}}{{fields.runs.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.runs.label}}">{{fields.runs.label}}<span *ngIf="fields.runs.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="runs"
                                matInput
                                numberBox
                                [format]="fields.runs.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.runs.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.runs.control.placeholder}}"
                                [ngStyle]="fields.runs.control.styles.style"
                                [ngClass]="fields.runs.control.styles.classes">
                        <ng-container *ngIf="fields.runs.invalid">
                          <ng-container *ngFor="let error of fields.runs.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="project"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.project.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.project.control.placeholder}}"
                                [ngStyle]="fields.project.control.styles.style"
                                [ngClass]="fields.project.control.styles.classes"> 
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse.label}}{{fields.warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse.label}}">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="warehouse"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.warehouse.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.warehouse.control.placeholder}}"
                                [ngStyle]="fields.warehouse.control.styles.style"
                                [ngClass]="fields.warehouse.control.styles.classes"> 
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_class" *ngIf="!fields.order_class.hidden" 
                            class="field-container standard {{fields.order_class.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_class.styles.style"
                            [ngClass]="fields.order_class.styles.classes">
                        <div class="label-container"
                              title="{{fields.order_class.label}}{{fields.order_class.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order_class.label}}">{{fields.order_class.label}}<span *ngIf="fields.order_class.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="order_class"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.order_class.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.order_class.control.placeholder}}"
                                [ngStyle]="fields.order_class.control.styles.style"
                                [ngClass]="fields.order_class.control.styles.classes"> 
                        <ng-container *ngIf="fields.order_class.invalid">
                          <ng-container *ngFor="let error of fields.order_class.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material" *ngIf="!fields.material.hidden" 
                            class="field-container standard {{fields.material.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                              title="{{fields.material.label}}{{fields.material.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material.label}}">{{fields.material.label}}<span *ngIf="fields.material.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="material"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.material.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.material.control.placeholder}}"
                                [ngStyle]="fields.material.control.styles.style"
                                [ngClass]="fields.material.control.styles.classes"> 
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-packaging" *ngIf="!fields.packaging.hidden" 
                            class="field-container standard {{fields.packaging.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.packaging.styles.style"
                            [ngClass]="fields.packaging.styles.classes">
                        <div class="label-container"
                              title="{{fields.packaging.label}}{{fields.packaging.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.packaging.label}}">{{fields.packaging.label}}<span *ngIf="fields.packaging.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="packaging"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.packaging.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.packaging.control.placeholder}}"
                                [ngStyle]="fields.packaging.control.styles.style"
                                [ngClass]="fields.packaging.control.styles.classes"> 
                        <ng-container *ngIf="fields.packaging.invalid">
                          <ng-container *ngFor="let error of fields.packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-stream" *ngIf="!fields.stream.hidden" 
                            class="field-container standard {{fields.stream.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.stream.styles.style"
                            [ngClass]="fields.stream.styles.classes">
                        <div class="label-container"
                              title="{{fields.stream.label}}{{fields.stream.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.stream.label}}">{{fields.stream.label}}<span *ngIf="fields.stream.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="stream"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.stream.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.stream.control.placeholder}}"
                                [ngStyle]="fields.stream.control.styles.style"
                                [ngClass]="fields.stream.control.styles.classes"> 
                        <ng-container *ngIf="fields.stream.invalid">
                          <ng-container *ngFor="let error of fields.stream.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-confirm" *ngIf="!fields.confirm.hidden" 
                            class="field-container standard {{fields.confirm.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.confirm.styles.style"
                            [ngClass]="fields.confirm.styles.classes">
                        <div class="label-container"
                              title="{{fields.confirm.label}}{{fields.confirm.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.confirm.label}}">{{fields.confirm.label}}<span *ngIf="fields.confirm.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="confirm"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.confirm.control.styles.style"
                                      [ngClass]="fields.confirm.control.styles.classes">{{fields.confirm.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.confirm.invalid">
                          <ng-container *ngFor="let error of fields.confirm.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-code_box_output"
                     *ngIf="!fieldsets.code_box_output.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.code_box_output.collapsible }">
                    <div *ngIf="!fieldsets.code_box_output.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.code_box_output.toggle()">
                      <span class="fieldsetsTitle-text">Output</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.code_box_output.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.code_box_output.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.code_box_output.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.code_box_output.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-code_box" *ngIf="!fields.code_box.hidden" 
                            class="field-container full {{fields.code_box.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.code_box.styles.style"
                            [ngClass]="fields.code_box.styles.classes">
                        <div class="label-container"
                              title="{{fields.code_box.label}}{{fields.code_box.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.code_box.label}}">{{fields.code_box.label}}<span *ngIf="fields.code_box.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <ngx-codemirror
                                    formControlName="code_box"
                                    [options]="{
                                    theme: 'base16-light',
                                    mode: fields.code_box.control.codeMirrorMode,
                                    lineNumbers: true,
                                    lineWrapping: true,
                                    foldGutter: true,
                                    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                                    autoCloseBrackets: true,
                                    matchBrackets: true,
                                    lint: true
                                    }"
                                    [CodeValidator]="fields.code_box.control.mode"
                                    >
                        </ngx-codemirror>
                        
                        <button type="button margin-top-10"
                                (click)="fields.code_box.control.beautify();"
                                class="datex-button secondary ">Beautify</button>
                        <ng-container *ngIf="fields.code_box.invalid">
                          <ng-container *ngFor="let error of fields.code_box.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-code_box_input"
                     *ngIf="!fieldsets.code_box_input.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.code_box_input.collapsible }">
                    <div *ngIf="!fieldsets.code_box_input.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.code_box_input.toggle()">
                      <span class="fieldsetsTitle-text">Input</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.code_box_input.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.code_box_input.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.code_box_input.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.code_box_input.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-code_box_input" *ngIf="!fields.code_box_input.hidden" 
                            class="field-container full {{fields.code_box_input.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.code_box_input.styles.style"
                            [ngClass]="fields.code_box_input.styles.classes">
                        <div class="label-container"
                              title="{{fields.code_box_input.label}}{{fields.code_box_input.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.code_box_input.label}}">{{fields.code_box_input.label}}<span *ngIf="fields.code_box_input.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <ngx-codemirror
                                    formControlName="code_box_input"
                                    [options]="{
                                    theme: 'base16-light',
                                    mode: fields.code_box_input.control.codeMirrorMode,
                                    lineNumbers: true,
                                    lineWrapping: true,
                                    foldGutter: true,
                                    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                                    autoCloseBrackets: true,
                                    matchBrackets: true,
                                    lint: true
                                    }"
                                    [CodeValidator]="fields.code_box_input.control.mode"
                                    >
                        </ngx-codemirror>
                        
                        <button type="button margin-top-10"
                                (click)="fields.code_box_input.control.beautify();"
                                class="datex-button secondary ">Beautify</button>
                        <ng-container *ngIf="fields.code_box_input.invalid">
                          <ng-container *ngFor="let error of fields.code_box_input.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

      </ng-container>

      <div *ngIf="initialized && !$hasDataLoaded " class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>