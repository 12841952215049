import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 


import { FootPrintManager_dock_appointment_activity_listComponent } from './FootPrintManager.dock_appointment_activity_list.component';
import { DockAppointments_dock_appointment_types_dd_singleComponent } from './DockAppointments.dock_appointment_types_dd_single.component'
import { DockAppointments_dock_appointment_statuses_singleComponent } from './DockAppointments.dock_appointment_statuses_single.component'
import { DockAppointments_dock_doors_dd_singleComponent } from './DockAppointments.dock_doors_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { DockAppointments_orders_dd_multiComponent } from './DockAppointments.orders_dd_multi.component'
import { Carriers_carriers_dd_singleComponent } from './Carriers.carriers_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_dock_appointment_activity_listComponent),
    forwardRef(() => DockAppointments_dock_appointment_types_dd_singleComponent),
    forwardRef(() => DockAppointments_dock_appointment_statuses_singleComponent),
    forwardRef(() => DockAppointments_dock_doors_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => DockAppointments_orders_dd_multiComponent),
    forwardRef(() => Carriers_carriers_dd_singleComponent),
  ],
  selector: 'app-custom_dock_appointment_editor',
  templateUrl: './app.custom_dock_appointment_editor.component.html'
})
export class app_custom_dock_appointment_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { dockAppointmentId: number, orderId?: number, loadContainerId?: number, shipmentId?: number } = { dockAppointmentId: null, orderId: null, loadContainerId: null, shipmentId: null };
  //#region Inputs
  @Input('dockAppointmentId') set $inParams_dockAppointmentId(v: number) {
    this.inParams.dockAppointmentId = v;
  }
  get $inParams_dockAppointmentId(): number {
    return this.inParams.dockAppointmentId;
  }
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  @Input('loadContainerId') set $inParams_loadContainerId(v: number) {
    this.inParams.loadContainerId = v;
  }
  get $inParams_loadContainerId(): number {
    return this.inParams.loadContainerId;
  }
  @Input('shipmentId') set $inParams_shipmentId(v: number) {
    this.inParams.shipmentId = v;
  }
  get $inParams_shipmentId(): number {
    return this.inParams.shipmentId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { orders?: number[], appointmentType?: string, doorType?: string } = { };
  //#endregion
  entity: { Id?: number, AssignedOn?: string, CancelledOn?: string, CheckedInOn?: string, CompletedOn?: string, DriverLicense?: string, DriverName?: string, InProcessOn?: string, LookupCode?: string, Notes?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledDeparture?: string, TypeId?: number, VehicleLicense?: string, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string, WarehouseId?: number }, ScheduledCarrier?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, Name?: string }, ScheduledProject?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, Items?: { Id?: number, ItemEntityId?: number, ItemEntityType?: string }[], AssignedLocation?: { Id?: number, Name?: string, WarehouseId?: number }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInOn?: { id?: number, convertedDate?: string }, convertedAssignedOn?: { id?: number, convertedDate?: string }, convertedInProcessOn?: { id?: number, convertedDate?: string }, convertedCompletedOn?: { id?: number, convertedDate?: string }, convertedCancelledOn?: { id?: number, convertedDate?: string } };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    type: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dockDoor: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    scheduled_arrival: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    scheduled_departure: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    scheduled_arrival_final: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    scheduled_departure_final: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      save: new ToolModel(new ButtonModel('save', new ButtonStyles(['primary'], null), false, 'Save', 'ms-Icon ms-Icon--Save')
    ),
      check_in: new ToolModel(new ButtonModel('check_in', new ButtonStyles(null, null), false, 'Check in', 'ms-Icon ms-Icon--BufferTimeBoth')
    ),
      on_assign_door: new ToolModel(new ButtonModel('on_assign_door', new ButtonStyles(null, null), false, 'Assign door', 'ms-Icon ms-Icon--FullWidthEdit')
    ),
      on_mark_in_process: new ToolModel(new ButtonModel('on_mark_in_process', new ButtonStyles(null, null), false, 'Mark In process', 'ms-Icon ms-Icon--FullHistory')
    ),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'ms-Icon ms-Icon--WorkFlow')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, 'Cancel appt', 'ms-Icon ms-Icon--Blocked')
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, ' ', 'ms-Icon ms-Icon--Print')
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'ms-Icon ms-Icon--Attach')
    ),
      surveys: new ToolModel(new ButtonModel('surveys', new ButtonStyles(null, null), false, ' ', 'ms-Icon ms-Icon--Questionnaire')
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Lookup', true)
,
    type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['type'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Type', true)
,
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  true, 
  '')
, new ControlContainerStyles(null, null), 'Status', false)
,
    dockDoor: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dockDoor'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Dock door', false)
,
    scheduled_arrival: new FieldModel(new DateBoxModel(this.formGroup.controls['scheduled_arrival'] as DatexFormControl, null, false, '', 'datetime')
, new ControlContainerStyles(null, null), 'Scheduled arrival', true)
,
    scheduled_departure: new FieldModel(new DateBoxModel(this.formGroup.controls['scheduled_departure'] as DatexFormControl, null, false, 'l, LT', 'datetime')
, new ControlContainerStyles(null, null), 'Scheduled departure', true)
,
    scheduled_arrival_final: new FieldModel(new DateBoxModel(this.formGroup.controls['scheduled_arrival_final'] as DatexFormControl, null, true, 'l, LT', 'date')
, new ControlContainerStyles(null, null), 'Scheduled Arrival', false)
,
    scheduled_departure_final: new FieldModel(new DateBoxModel(this.formGroup.controls['scheduled_departure_final'] as DatexFormControl, null, true, 'l, LT', 'date')
, new ControlContainerStyles(null, null), 'Scheduled Departure', false)
,
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', false)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', false)
,
    order: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Order', false)
,
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Carrier', false)
,
    reference_number: new FieldModel(new TextBoxModel(this.formGroup.controls['reference_number'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Reference number', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      activity: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_activity_dock_appointment_activity_list_inParams_dockAppointmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.dockAppointmentId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_activity', { read: FootPrintManager_dock_appointment_activity_listComponent }) $tabs_activity: FootPrintManager_dock_appointment_activity_listComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_type_selector_inParams_option(): string {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.doorType;
    
    return expr;
  }

  cacheValueFor_$fields_dockDoor_selector_inParams_warehouseIds: number[];
  get $fields_dockDoor_selector_inParams_warehouseIds(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = [$editor.entity.WarehouseId];
    
    if(!isEqual(this.cacheValueFor_$fields_dockDoor_selector_inParams_warehouseIds, expr)) {
      this.cacheValueFor_$fields_dockDoor_selector_inParams_warehouseIds = expr;
    }
    return this.cacheValueFor_$fields_dockDoor_selector_inParams_warehouseIds;
  }

  get $fields_dockDoor_selector_inParams_appointmentType(): string {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.appointmentType;
    
    return expr;
  }

  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  get $fields_order_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_order_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.entity.WarehouseId;
    
    return expr;
  }

  get $fields_order_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.type.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    type: this.fields.type.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    dockDoor: this.fields.dockDoor.control.valueChanges
    ,
    scheduled_arrival: this.fields.scheduled_arrival.control.valueChanges
    ,
    scheduled_departure: this.fields.scheduled_departure.control.valueChanges
    ,
    scheduled_arrival_final: this.fields.scheduled_arrival_final.control.valueChanges
    ,
    scheduled_departure_final: this.fields.scheduled_departure_final.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    order: this.fields.order.control.valueChanges
    ,
    carrier: this.fields.carrier.control.valueChanges
    ,
    reference_number: this.fields.reference_number.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.activity,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.dockAppointmentId)) {
        this.$missingRequiredInParams.push('dockAppointmentId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Dock Appointment Editor';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      dockAppointmentId:  $editor.inParams.dockAppointmentId 
    };

    const data = await this.datasources.DockAppointments.ds_dock_appointment_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.type.control as SelectBoxModel).reset($editor.entity.TypeId);
    (this.fields.status.control as SelectBoxModel).reset($editor.entity.Status?.Id);
    (this.fields.dockDoor.control as SelectBoxModel).reset($utils.isDefined($editor.entity.AssignedLocation?.Id) ? $editor.entity.AssignedLocation?.Id : $editor.entity.ScheduledLocation?.Id);
    (this.fields.scheduled_arrival.control as DateBoxModel).reset($editor.entity.convertedScheduledArrival?.convertedDate);
    (this.fields.scheduled_departure.control as DateBoxModel).reset($editor.entity.convertedScheduledDeparture?.convertedDate);
    (this.fields.scheduled_arrival_final.control as DateBoxModel).reset($editor.entity.ScheduledArrival);
    (this.fields.scheduled_departure_final.control as DateBoxModel).reset($editor.entity.ScheduledDeparture);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.ScheduledOwner?.Id);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ScheduledProject?.Id);
    (this.fields.order.control as SelectBoxModel).reset($utils.isDefined($editor.vars.orders) ? $editor.vars.orders :  null );
    (this.fields.carrier.control as SelectBoxModel).reset($editor.entity.ScheduledCarrier?.Id);
    (this.fields.reference_number.control as TextBoxModel).reset($editor.entity.ReferenceNumber);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_activity') {
        if (!isNil(this.$tabs_activity) && !this.tabs.activity.hidden) {
          this.$tabs_activity.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .type
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_type_change();
      });
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dockDoor
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_door_change();
      });
    this.$formGroupFieldValidationObservables
      .scheduled_arrival
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_scheduled_arrival_change();
      });
    this.$formGroupFieldValidationObservables
      .scheduled_departure
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .scheduled_arrival_final
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_scheduled_arrival_change();
      });
    this.$formGroupFieldValidationObservables
      .scheduled_departure_final
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.$formGroupFieldValidationObservables
      .order
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .carrier
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reference_number
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_save_clicked(event = null) {
    return this.on_save_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_save_clicked');
  
  
  $editor.toolbar.save.control.readOnly = true;
  
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.type.control.value,
      $editor.fields.scheduled_arrival.control.value,
      $editor.fields.scheduled_departure.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      $editor.toolbar.save.control.readOnly = false;
      $shell.DockAppointments.openErrorDialog('Appointment Edit Error', 'Please enter data in all required fields.');
      return;
  }
  
  
  // Validate Fields
  // Enforce 15 minute window between scheduled arrival and scheduled departure
  if ($editor.entity.Status.Id == 0) {
      if ($editor.fields.scheduled_departure.control.value < $utils.date.add(15, 'minute', $editor.fields.scheduled_arrival.control.value)) {
          $editor.toolbar.save.control.readOnly = false;
          $shell.DockAppointments.openErrorDialog('Appointment Edit Error', 'The scheduled departure must be at least 15 minutes after the scheduled arrival.');
          return;
      }
  }
  
  // Update dock appointment
  
  let warehouse = (await $datasources.DockAppointments.ds_get_warehouse_by_warehouseId.get({ warehouseId: $editor.entity.WarehouseId })).result;
  if ($utils.isDefined(warehouse)) {
  
      var timezone = warehouse.TimeZoneId;
  }
  
  let payload: any = {};
  
  
  if ($editor.fields.dockDoor.control.isChanged) {
  
      if ($editor.entity.Status.Id == 0 || $editor.entity.Status.Id == 1) { // Open or In-Yard
  
          payload.ScheduledLocationContainerId = $editor.fields.dockDoor.control.value;
  
      }
      else {
  
          if ($utils.isDefined($editor.fields.dockDoor.control.value)) {
  
              payload.AssignedlLocationContainerId = $editor.fields.dockDoor.control.value;
              payload.ScheduledLocationContainerId = $editor.fields.dockDoor.control.value;
              
          }
          else {
  
              // Update dock appointment back to In-Yard
  
              payload.StatusId = 1,
              payload.AssignedlLocationContainerId = null
  
  
  
          }
  
  
      }
  }
  
  if ($editor.fields.scheduled_arrival.control.isChanged) {
  
      let resultArrival = await $flows.DockAppointments.get_utc_date_by_timezone({
          dateTime: $editor.fields.scheduled_arrival.control.value,
          timezone: timezone
      });
  
      if ($utils.isDefined(resultArrival)) {
          var scheduledArrivalUtc = resultArrival.utcDate;
  
      }
  
      payload.ScheduledArrival = scheduledArrivalUtc;
  }
  
  
  if ($editor.fields.scheduled_departure.control.isChanged) {
  
      let resultDeparture = await $flows.DockAppointments.get_utc_date_by_timezone({
          dateTime: $editor.fields.scheduled_departure.control.value,
          timezone: timezone
      });
  
      if ($utils.isDefined(resultDeparture)) {
          var scheduledDeparturelUtc = resultDeparture.utcDate;
  
      }
  
      payload.ScheduledDeparture = scheduledDeparturelUtc;
  }
  
  
  if ($editor.fields.owner.control.isChanged) {
      payload.ScheduledOwnerId = $editor.fields.owner.control.value;
  }
  
  
  if ($editor.fields.project.control.isChanged) {
      payload.ScheduledProjectId = $editor.fields.project.control.value;
  }
  
  if ($editor.fields.carrier.control.isChanged) {
      payload.ScheduledCarrierId = $editor.fields.carrier.control.value;
  }
  if ($editor.fields.reference_number.control.isChanged) {
      payload.ReferenceNumber = $editor.fields.reference_number.control.value;
  }
  
  if ($editor.fields.type.control.isChanged) {
      payload.TypeId = $editor.fields.type.control.value;
  }
  
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  
  // Status change only available when appointment is in a completed status
  if ($editor.fields.status.control.isChanged){
      payload.StatusId = $editor.fields.status.control.value;
  }
  
  
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.inParams.dockAppointmentId, entity: payload });
  
  
  }
  
  catch (error) {
      $editor.toolbar.save.control.readOnly = false;
      $shell.DockAppointments.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  
  // Update dock appointment items
  let payloadItems: any = {};
  
  if ($editor.fields.order.control.isChanged || $utils.isDefined($editor.inParams.orderId)) {
  
      var selectedOrders = $editor.fields.order.control.value;
  
      // If there is already an order associated to the appointment
      if ($utils.isDefined($editor.entity.Items[0]?.Id)) {
  
  
          try {
  
  
              // Remove all orders asscociated to the appointment
              const orders = $editor.entity.Items;
              for (const order of orders) {
  
                  if ($utils.isDefined(order.ItemEntityId)) {
  
                      const dissociateOrderRequest = (await $flows.DockAppointments.diassociate_orders_from_dock_appointment_flow({
                          dockAppointmentId: $editor.inParams.dockAppointmentId,
                          orderId: order.ItemEntityId
                      }));
                  }
  
              }
  
  
              if ($utils.isDefined($editor.fields.order.control.value)) {
                  // Associate the new order to the existing appointment
                  for (const selectedOrder of selectedOrders) {
                      const dockAppointmentItemRequest = (await $flows.DockAppointments.associate_orders_from_dock_appointment_flow({
                          dockAppointmentId: $editor.inParams.dockAppointmentId,
                          orderId: selectedOrder
                      }));
                  }
                  const order = (await $datasources.DockAppointments.ds_get_order_by_orderId.get({ orderId: $editor.fields.order.control.value })).result;
                  if ($utils.isDefined(order)) {
                      const ownerId = order.Project.OwnerId;
                      const projectId = order.ProjectId;
  
                      // Update dock appointment
                      let payload: any = {};
                      payload.ScheduledOwnerId = ownerId;
                      payload.ScheduledProjectId = projectId;
                      await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.inParams.dockAppointmentId, entity: payload });
  
                  }
              }
          }
  
          catch (error) {
              $editor.toolbar.save.control.readOnly = false;
              $shell.DockAppointments.showErrorDetails('Save', 'Error on save.', error);
              throw error; // to prevent displayMode 
  
          }
  
          // No existing order associated to the appointment
      } else {
  
          try {
  
              if ($utils.isDefined($editor.fields.order.control.value)) {
  
                  // Associate the new order to the existing appointment
                  for (const selectedOrder of selectedOrders) {
                      const dockAppointmentItemRequest = (await $flows.DockAppointments.associate_orders_from_dock_appointment_flow({
                          dockAppointmentId: $editor.inParams.dockAppointmentId,
                          orderId: selectedOrder
                      }));
                  }
                  const order = (await $datasources.DockAppointments.ds_get_order_by_orderId.get({ orderId: $editor.fields.order.control.value })).result;
                  if ($utils.isDefined(order)) {
                      const ownerId = order.Project.OwnerId;
                      const projectId = order.ProjectId;
  
                      // Update dock appointment
                      let payload: any = {};
                      payload.ScheduledOwnerId = ownerId;
                      payload.ScheduledProjectId = projectId;
                      await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.inParams.dockAppointmentId, entity: payload });
  
                  }
              }
  
  
          } catch (error) {
              $editor.toolbar.save.control.readOnly = false;
              $shell.DockAppointments.showErrorDetails('Save', 'Error on save.', error);
              throw error; // to prevent displayMode 
          }
  
  
      }
  }
  
  // Set confirmation outParam and close editor
  $editor.toolbar.save.control.readOnly = false;
  $editor.outParams.confirm = true;
  
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_owner_change');
  
  // Clear out the project selection
  $editor.fields.project.control.value = null;
  
  // Clear out the order selection
  if ($utils.isDefined($editor.fields.order.control.value)) {
      $editor.fields.order.control.value = null;
      $editor.fields.order.control.displayText = null;
  }
  
  
  
  }
  on_type_change(event = null) {
    return this.on_type_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_type_changeInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_type_change');
  const appointmentType = (await $datasources.DockAppointments.ds_dock_appointment_type_by_typeId.get({
      typeId: $editor.fields.type.control.value
      })).result;
  
  if($utils.isDefined(appointmentType)){
      $editor.vars.appointmentType = appointmentType.Options;
  }
  
  if ($editor.fields.type.control.value == 1 || $editor.fields.type.control.value == 2 ){
  
  // Clear out the order selection
  $editor.fields.order.hidden = false;
  $editor.fields.order.control.value = null;
  $editor.fields.order.control.displayText = null;
  
  
  }
  else {
  
      $editor.fields.order.hidden = true;
  }
  
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_project_change');
  // Clear out the order selection
  if ($utils.isDefined($editor.fields.order.control.value)) {
      $editor.fields.order.control.value = null;
      $editor.fields.order.control.displayText = null;
  }
  
  if ($editor.entity.WarehouseId == null){
  
      $editor.fields.order.hidden = true;
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_init');
  
  // Set Date/Time Formats
  $editor.fields.scheduled_arrival.control.format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  $editor.fields.scheduled_departure.control.format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  
  
  // Hide the orders drop down if the load container id parameter is passed in
  if ($utils.isDefined($editor.inParams.loadContainerId)) {
  
      $editor.fields.order.control.readOnly = true;
      $editor.fields.order.hidden = true;
  
  };
  
  }
  on_scheduled_arrival_change(event = null) {
    return this.on_scheduled_arrival_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_scheduled_arrival_changeInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_scheduled_arrival_change');
  //Only update the schedule departure date if it's not populated or if it's earlier
  if ($editor.fields.scheduled_arrival.control.isChanged
      && $utils.isDefined($editor.fields.scheduled_arrival.control.value)
      && (!$utils.isDefined($editor.fields.scheduled_departure.control.value)
          || Date.parse($editor.fields.scheduled_arrival.control.value) > Date.parse($editor.fields.scheduled_departure.control.value)
      )
  ) {
      $editor.fields.scheduled_departure.control.value = $utils.date.add(1, 'hour', $editor.fields.scheduled_arrival.control.value);
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_delete_clicked');
  //O.Arias - 02/24/2023 - Initial Development
  
  // Set some default values to some variables
  let is_deletable = 'No';
  let assignment_exists = 'No';
  let message_box_context = 'Are you sure you want to delete appointment ' + $editor.entity.LookupCode + '?';
  
  // Gather dock appointment status
  const dockAppt = (await $datasources.DockAppointments.ds_get_dock_appointment_by_appointmentId.get({
      appointmentId: $editor.entity.Id
  })).result;
  
  // Check for valid statuses
  if ($utils.isDefined(dockAppt)) {
      const dockStatus = dockAppt.StatusId
      if (dockStatus == 0) { is_deletable = 'Yes' }; //Created
      if (dockStatus == 5) { is_deletable = 'Yes' }; //Cancelled
      if (dockStatus == 1) { is_deletable = 'In-Yard' };
      if (dockStatus == 2) { is_deletable = 'Door Assigned' };
      if (dockStatus == 3) { is_deletable = 'In-Process' };
      if (dockStatus == 4) { is_deletable = 'Completed' };
  }
  
  if (is_deletable == 'Yes') {
  
      //Check for existing items
      const dockAppointmentItems = (await $datasources.DockAppointments.ds_dock_appointment_items.get({ dockAppointmentId: $editor.entity.Id })).result;
  
      //Populate the additional context to the message box
      if ($utils.isDefined(dockAppointmentItems)) {
          assignment_exists = 'True';
          message_box_context = 'The appointment is already assigned! ' + message_box_context + ' All current assignments will be deleted!';
      };
  
      //Making sure the message box has data
      if ($utils.isDefined(message_box_context)) {
  
          const confirm = await $shell.DockAppointments.openConfirmationDialog('Delete Dock Appointment ' + $editor.entity.LookupCode, message_box_context, 'Yes', 'No');
          if (confirm) {
              try {
  
                  //Delete the dock appointment items
                  if ($utils.isDefined(dockAppointmentItems)) {
                      for (let item of dockAppointmentItems) {
                          let itemId = item.Id;
                          //Delete dock appointment item record
                          await $flows.Utilities.crud_delete_flow({ entitySet: 'DockAppointmentItems', id: itemId });
                      }
                  }
  
                  //Delete the dock appointment
                  await $flows.Utilities.crud_delete_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id });
  
                  //Set the confirm output to true so it refreshes the caller
                  $editor.outParams.confirm = true;
                  //Close the editor
                  $editor.close();
  
              } catch (error) {
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                  const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                  const errorDescription = `Dock Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
                  await $shell.DockAppointments.openErrorDialog('Cannot Delete Dock Appointment', 'An error occurred during the deletion of the dock appointment: ', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
              }
          }
      } else {
          await $shell.DockAppointments.openErrorDialog('Cannot Delete Dock Appointment', 'Unhandled Exception!');
          return;
      };
  
  }
  else {
      if (is_deletable !== 'Yes') {
          message_box_context = 'Dock appointment ' + $editor.entity.LookupCode + ' is in [' + is_deletable + '] status and cannot be deleted!';
      } else {
          message_box_context = 'Unhandled Exception!'
      };
      await $shell.DockAppointments.openErrorDialog('Cannot Delete Dock Appointment', message_box_context);
      return;
  };
  
  
  }
  on_check_in_clicked(event = null) {
    return this.on_check_in_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_check_in_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_check_in_clicked');
  
  $editor.toolbar.check_in.control.readOnly = true;
  
  
  if ($utils.isDefined($editor.entity.WarehouseId)) {
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_check_in_options_formDialog({
          dockAppointmentId: $editor.inParams.dockAppointmentId,
          warehouseId: $editor.entity.WarehouseId,
          scheduledDockDoorId: $editor.fields.dockDoor.control.value
      });
      var userConfirmed = dialogResult.confirm;
  
      var scheduledDoorId = dialogResult.scheduledDoorId;
      var assignedDoorId = dialogResult.assignedDoorId;
      var driverName = dialogResult.driverName;
      var driverLicense = dialogResult.driverLicense;
      var vehicleLicense = dialogResult.vehicleLicense;
  
  
      if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
          $editor.toolbar.check_in.control.readOnly = false;
          return;
      }
  
      if (userConfirmed) {
          try {
  
              let warehouse = (await $datasources.DockAppointments.ds_get_warehouse_by_warehouseId.get({ warehouseId: $editor.entity.WarehouseId })).result;
              if ($utils.isDefined(warehouse)) {
                  var timezone = warehouse.TimeZoneId;
              }
  
              const user = (await $flows.Utilities.get_username_flow({})).userName;
              // Cannot use native actions as timezone is conflicting
  
              // const result = await $apis.DockAppointments.FootPrintAPI.DockAppointments.CheckInDockAppointment({
              //     DockAppointmentId: $editor.entity.Id,
              //     UserName: user,
              //     DriverName: driverName,
              //     DriverLicenseNo: driverLicense,
              //     EquipmentLicenseNo: equipmentLicense
              // });
  
              // Update dock appointment
              let payload: any = {};
  
              var resultUtc = $utils.date.add(new Date().getTimezoneOffset(), "minute", $utils.date.now());
              //  Commented out the newScheduledDeparture date as the customer
              //  didn't want the scheduled dates to be tampered with after check-in.
  
              //var newScheduledDeparture = $utils.date.add(1, 'hour', resultUtc)
              //var newScheduledDeparture = $utils.date.add(1, 'hour', resultUtc)
  
              if ($utils.isDefined($editor.fields.scheduled_departure.control.value)) {
  
                  let resultDeparture = (await $flows.DockAppointments.get_utc_date_by_timezone({
                      dateTime: $editor.fields.scheduled_departure.control.value,
                      timezone: timezone
                  }))?.utcDate
  
                  if (Date.parse(resultUtc) > Date.parse(resultDeparture)) {
                      throw new Error(`The schedule departure date ${resultDeparture} UTC is earlier than the check-in date ${resultUtc} UTC!`)
                  }
              } else {
                  payload.ScheduledDeparture = $utils.date.add(1, 'hour', resultUtc)
              }
  
              payload.CheckedInBy = user,
                  payload.DriverName = driverName,
                  payload.DriverLicense = driverLicense,
                  payload.VehicleLicense = vehicleLicense,
                  payload.StatusId = 1,
                  payload.CheckedInOn = resultUtc
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
  
  
              if ($utils.isDefined(scheduledDoorId)) {
                  // Update dock appointment
                  let payload: any = {};
                  payload.ScheduledLocationContainerId = scheduledDoorId;
                  await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
  
              }
  
              if ($utils.isDefined(assignedDoorId)) {
                  // const result = await $apis.DockAppointments.FootPrintAPI.DockAppointments.AssignDoorToDockAppointment({
                  //     DockAppointmentId: $editor.entity.Id,
                  //     UserName: user,
                  //     DockDoorId: assignedDoorId
                  // });
  
                  // Update dock appointment
                  let payload: any = {};
                  payload.AssignedBy = user,
                      payload.StatusId = 2,
                      payload.AssignedOn = resultUtc,
                      //payload.ScheduledDeparture = newScheduledDeparture,
                      payload.AssignedlLocationContainerId = assignedDoorId
  
                  await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
              }
  
              // Set confirmation outParam and refresh editor
              $editor.toolbar.check_in.control.readOnly = false;
              $editor.refresh();
              $editor.outParams.confirm = true;
  
  
  
          } catch (error) {
              $editor.toolbar.check_in.control.readOnly = false;
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.DockAppointments.openErrorDialog('Check in appointment error', 'An error occurred during checking in of the appointment', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  else {
      throw new Error('Unable to check in appointment, please first select a dock door.')
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_data_loaded');
  // Pass the appointment type into the variable which is used as a filter on the dock door
  const appointmentType = (await $datasources.DockAppointments.ds_dock_appointment_type_by_typeId.get({
      typeId: $editor.fields.type.control.value
      })).result;
  
  if($utils.isDefined(appointmentType)){
      $editor.vars.appointmentType = appointmentType.Options;
  }
  
  
  var dockAppointmentStatus = $editor.entity.Status.Id;
  $editor.vars.orders = [];
  
  // Ignore building the order variable if the load container id parameter is passed in
  if (!$utils.isDefined($editor.inParams.loadContainerId)) {
  
      // Set orders variable to all assigned orders
      if ($utils.isDefined($editor.entity.Items)) {
  
          const orders = $editor.entity.Items;
          for (const order of orders) {
  
              if ($utils.isDefined(order.ItemEntityId)) {
                  $editor.vars.orders.push(order.ItemEntityId);
              }
  
          }
  
      }
      if ($utils.isDefined($editor.inParams.orderId)) {
          const numberArray: number[] = [$editor.inParams.orderId];
          const orderId: number = parseInt(numberArray.join(''));
  
          $editor.vars.orders.push(orderId)
      }
  
      $editor.fields.order.control.value = $editor.vars.orders;
  
  };
  
  // Set status field to read only by default since the status can only manually change when the appointment is a in a completed status
  $editor.fields.status.control.readOnly = true;
  
  // Change label of dock door to scheduled vs assigned based on state of the appointment
  
  if ($editor.entity.Status.Id == 0) // Open
  {
      $editor.fields.dockDoor.label = 'Scheduled Dock Door'
  
      // Hidden
      $editor.toolbar.on_assign_door.hidden = true;
      $editor.toolbar.on_mark_in_process.hidden = true;
      $editor.toolbar.complete.hidden = true;
      $editor.fields.scheduled_arrival_final.hidden = true;
      $editor.fields.scheduled_departure_final.hidden = true;
  
      // Visible
      $editor.toolbar.check_in.hidden = false;
      $editor.toolbar.cancel.hidden = false;
      $editor.toolbar.on_delete.hidden = false;
  
  }
  
  else if ($editor.entity.Status.Id == 1) {  // In-Yard
  
      $editor.fields.dockDoor.label = 'Scheduled Dock Door';
  
      // Hidden
      $editor.toolbar.check_in.hidden = true;
      $editor.toolbar.on_mark_in_process.hidden = true;
      $editor.fields.scheduled_arrival_final.hidden = true;
      $editor.fields.scheduled_departure_final.hidden = true;
  
      // Visible
      $editor.toolbar.on_assign_door.hidden = false
      $editor.toolbar.complete.hidden = false;
      $editor.toolbar.cancel.hidden = false;
      $editor.toolbar.on_delete.hidden = false;
      $editor.fields.scheduled_arrival.control.readOnly = true;
      $editor.fields.scheduled_departure.control.readOnly = true;
  
  
  }
  
  else if ($editor.entity.Status.Id == 2) {  // Door Assigned
  
      $editor.fields.dockDoor.label = 'Assigned Dock Door'
  
      // Hidden
      $editor.toolbar.check_in.hidden = true;
      $editor.toolbar.on_assign_door.hidden = true;
      $editor.toolbar.on_delete.hidden = true;
      $editor.fields.scheduled_arrival_final.hidden = true;
      $editor.fields.scheduled_departure_final.hidden = true;
  
      // Visible
      $editor.toolbar.on_mark_in_process.hidden = false;
      $editor.toolbar.complete.hidden = false;
      $editor.toolbar.cancel.hidden = false;
      $editor.fields.scheduled_arrival.control.readOnly = true;
      $editor.fields.scheduled_departure.control.readOnly = true;
  
  
  }
  
  else if ($editor.entity.Status.Id == 3) {  // In process
  
      $editor.fields.dockDoor.label = 'Assigned Dock Door'
  
      // Hidden
      $editor.toolbar.check_in.hidden = true;
      $editor.toolbar.on_assign_door.hidden = true;
      $editor.toolbar.on_mark_in_process.hidden = true;
      $editor.toolbar.on_delete.hidden = true;
      $editor.fields.scheduled_arrival_final.hidden = true;
      $editor.fields.scheduled_departure_final.hidden = true;
  
      // Visible
      $editor.toolbar.complete.hidden = false;
      $editor.toolbar.cancel.hidden = false;
  
  
      // Dates
  
      $editor.fields.scheduled_arrival.control.readOnly = true;
      $editor.fields.scheduled_departure.control.readOnly = true;
  }
  
  else if ($editor.entity.Status.Id == 4) {  // Completed
  
      $editor.fields.dockDoor.label = 'Assigned Dock Door'
  
      // Allow status field to be changed
      $editor.fields.status.control.readOnly = false;
  
      // Hidden
      $editor.toolbar.check_in.hidden = true;
      $editor.toolbar.on_assign_door.hidden = true;
      $editor.toolbar.on_mark_in_process.hidden = true;
      $editor.toolbar.complete.hidden = true;
      $editor.toolbar.cancel.hidden = true;
      $editor.toolbar.on_delete.hidden = true;
  
  
      // Readonly
      $editor.fields.dockDoor.control.readOnly = true;
      $editor.fields.scheduled_arrival.control.readOnly = true;
      $editor.fields.scheduled_departure.control.readOnly = true;
      $editor.fields.owner.control.readOnly = true;
      $editor.fields.project.control.readOnly = true;
      $editor.fields.order.control.readOnly = true;
      $editor.fields.carrier.control.readOnly = true;
      $editor.fields.scheduled_arrival_final.control.readOnly = true;
      $editor.fields.scheduled_departure_final.control.readOnly = true;
  
      // Dates
      $editor.fields.scheduled_departure.label = 'Completed On';
      $editor.fields.scheduled_departure.control.value = $editor.entity.convertedCompletedOn?.convertedDate;
  
      var arivalDate = $utils.isDefined($editor.entity.convertedInProcessOn?.convertedDate) ? $editor.entity.convertedInProcessOn?.convertedDate :
          ($utils.isDefined($editor.entity.convertedAssignedOn?.convertedDate) ? $editor.entity.convertedAssignedOn?.convertedDate :
              ($utils.isDefined($editor.entity.convertedCheckInOn?.convertedDate) ? $editor.entity.convertedCheckInOn?.convertedDate : null))
  
      var arrivalLabel = $utils.isDefined($editor.entity.InProcessOn) ? 'In Process On' :
          ($utils.isDefined($editor.entity.AssignedOn) ? 'Assigned On' :
              ($utils.isDefined($editor.entity.CheckedInOn) ? 'Checked In On' : ''))
  
      $editor.fields.scheduled_arrival.label = $utils.isDefined(arrivalLabel) ? arrivalLabel : 'Scheduled Arrival';
      $editor.fields.scheduled_arrival.control.value = $utils.isDefined(arivalDate) ? arivalDate : $editor.entity.convertedScheduledArrival.convertedDate;
  
  
  }
  
  else if ($editor.entity.Status.Id == 5) {  // Cancelled
  
      $editor.fields.dockDoor.label = 'Assigned Dock Door'
  
  
  
      // Hidden
      $editor.toolbar.check_in.hidden = true;
      $editor.toolbar.on_assign_door.hidden = true;
      $editor.toolbar.on_mark_in_process.hidden = true;
      $editor.toolbar.complete.hidden = true;
      $editor.toolbar.cancel.hidden = true;
      $editor.toolbar.on_delete.hidden = true;
  
  
      // Readonly
      $editor.fields.dockDoor.control.readOnly = true;
      $editor.fields.scheduled_arrival.control.readOnly = true;
      $editor.fields.scheduled_departure.control.readOnly = true;
      $editor.fields.owner.control.readOnly = true;
      $editor.fields.project.control.readOnly = true;
      $editor.fields.order.control.readOnly = true;
      $editor.fields.carrier.control.readOnly = true;
      $editor.fields.scheduled_arrival_final.control.readOnly = true;
      $editor.fields.scheduled_departure_final.control.readOnly = true;
  
      // Dates
      $editor.fields.scheduled_departure.label = 'Cancelled On';
      $editor.fields.scheduled_departure.control.value = $editor.entity.convertedCancelledOn?.convertedDate;
  
      $editor.fields.scheduled_arrival.hidden = true;
  
  
  }
  
  // If the dock door has a value call the door_change flow to set the doorType variable
  if ($utils.isDefined($editor.entity.ScheduledLocation) || $utils.isDefined($editor.entity.AssignedLocation)){
  
      $editor.on_door_change();
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_cancel_clicked');
  
  
  $editor.toolbar.cancel.control.readOnly = true;
  
  const dialogResult = await $shell.FootPrintManager.opendock_appointment_cancel_options_formDialog({});
  var userConfirmed = dialogResult.confirm;
  var reasonCodeId = dialogResult.reasonCodeId;
  
  
  
  if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
      $editor.toolbar.cancel.control.readOnly = false;
      return;
  }
  
  if (userConfirmed) {
      try {
  
          // Cannot use native actions as timezone is conflicting
          const user = (await $flows.Utilities.get_username_flow({})).userName;
  
          if ($utils.isDefined(reasonCodeId)) {
              // const result = await $apis.DockAppointments.FootPrintAPI.DockAppointments.CancelDockAppointment({
              //     DockAppointmentId: $editor.entity.Id,
              //     UserName: user,
              //     ReasonCodeId: reasonCodeId
              // });
              var resultUtc = $utils.date.add(new Date().getTimezoneOffset(), "minute", $utils.date.now());
  
  
              let payload: any = {};
  
  
              payload.CancelledBy = user,
                  payload.StatusId = 5,
                  payload.CancelledOn = resultUtc,
                  payload.CancelledReasonCodeId = reasonCodeId
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
          }
  
          // Remove all orders asscociated to the appointment
          const orders = $editor.entity.Items;
          for (const order of orders) {
  
              if ($utils.isDefined(order.ItemEntityId)) {
  
                  const dissociateOrderRequest = (await $flows.DockAppointments.diassociate_orders_from_dock_appointment_flow({
                      dockAppointmentId: $editor.inParams.dockAppointmentId,
                      orderId: order.ItemEntityId
                  }));
              }
  
          }
  
  
          // Set confirmation outParam and close editor
          $editor.outParams.confirm = true;
          $editor.close();
  
  
      } catch (error) {
          $editor.toolbar.cancel.control.readOnly = false;
  
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.DockAppointments.openErrorDialog('Assigning door on appointment error', 'An error occurred during the door assignment of the appointment.', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  
  
  
      }
  }
  
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_complete_clicked');
  
  
  $editor.toolbar.complete.control.readOnly = true;
  
  const userConfirmed = await $shell.DockAppointments.openConfirmationDialog('Complete Appointment', `Are you sure you want to complete appointment  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  
  
  if (userConfirmed) {
      try {
  
  
          // Cannot use native actions as timezone is conflicting
          const user = (await $flows.Utilities.get_username_flow({})).userName;
  
          // const result = await $apis.DockAppointments.FootPrintAPI.DockAppointments.CompleteDockAppointment({
          //     DockAppointmentId: $editor.entity.Id,
          //     UserName: user
          // });
          let payload: any = {};
  
          var resultUtc = $utils.date.add(new Date().getTimezoneOffset(), "minute", $utils.date.now());
  
  
          payload.CompletedBy = user,
              payload.StatusId = 4,
              payload.CompletedOn = resultUtc
  
          await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
  
  
          // Set confirmation outParam and close editor
          $editor.outParams.confirm = true;
          $editor.close();
  
  
  
      } catch (error) {
  
          $editor.toolbar.complete.control.readOnly = false;
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.DockAppointments.openErrorDialog('Complete appointment error', 'An error occurred during completion of the appointment', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  
  
  
      }
  }
  
  else {
      $editor.toolbar.complete.control.readOnly = false;
  }
  
  }
  on_mark_in_process_clicked(event = null) {
    return this.on_mark_in_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_mark_in_process_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_mark_in_process_clicked');
  
  $editor.toolbar.on_mark_in_process.control.readOnly = true;
  
  
  const userConfirmed = await $shell.DockAppointments.openConfirmationDialog('Mark In Process Appointment', `Are you sure you want to mark appointment ${$editor.entity.LookupCode} in process?`, 'Yes', 'No');
  
  
  if (userConfirmed) {
      try {
  
          // Cannot use native actions as timezone is conflicting
          const user = (await $flows.Utilities.get_username_flow({})).userName;
  
          //     const result = await $apis.DockAppointments.FootPrintAPI.DockAppointments.MarkInProcessDockAppointment({
          //     DockAppointmentId: $editor.entity.Id,
          //     UserName: user
          // });
  
          let payload: any = {};
  
          var resultUtc = $utils.date.add(new Date().getTimezoneOffset(), "minute", $utils.date.now());
          var newScheduledDeparture = $utils.date.add(1, 'hour', resultUtc)
  
          payload.InProcesesBy = user,
              payload.StatusId = 3,
              payload.InProcessOn = resultUtc,
              payload.ScheduledDeparture = newScheduledDeparture
  
          await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
  
  
          // Set confirmation outParam and refresh editor
          $editor.toolbar.on_mark_in_process.control.readOnly = false;
          $editor.refresh();
          $editor.outParams.confirm = true;
  
  
      } catch (error) {
  
          $editor.toolbar.on_mark_in_process.control.readOnly = false;
  
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.DockAppointments.openErrorDialog('Mark in process appointment error', 'An error occurred during setting the appointment to in process.', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  
  
  
      }
  }
  else {
      $editor.toolbar.on_mark_in_process.control.readOnly = false;
  }
  
  
  
  }
  on_assign_door_clicked(event = null) {
    return this.on_assign_door_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_assign_door_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_assign_door_clicked');
  
  
  
  $editor.toolbar.on_assign_door.control.readOnly = true;
  
  const dialogResult = await $shell.FootPrintManager.opendock_appointment_assign_door_options_formDialog({
      dockAppointmentId: $editor.inParams.dockAppointmentId,
      warehouseId: $editor.entity.WarehouseId,
      scheduledDockDoorId: $editor.fields.dockDoor.control.value
  });
  var userConfirmed = dialogResult.confirm;
  var assignedDoorId = dialogResult.assignedDoorId;
  
  
  
  if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
      $editor.toolbar.on_assign_door.control.readOnly = false;
      return;
  }
  
  if (userConfirmed) {
      try {
  
          //$utils.date.add(new Date().getTimezoneOffset(), "minute", $utils.date.now())
  
          // Cannot use native actions as timezone is conflicting
          const user = (await $flows.Utilities.get_username_flow({})).userName;
          if ($utils.isDefined(assignedDoorId)) {
              // const result = await $apis.DockAppointments.FootPrintAPI.DockAppointments.AssignDoorToDockAppointment({
              //     DockAppointmentId: $editor.entity.Id,
              //     UserName: user,
              //     DockDoorId: assignedDoorId
              // });
              // Update dock appointment
  
  
              var resultUtc = $utils.date.add(new Date().getTimezoneOffset(), "minute", $utils.date.now());
              var newScheduledDeparture = $utils.date.add(1, 'hour', resultUtc)
  
              let payload: any = {};
  
              payload.AssignedBy = user,
                  payload.StatusId = 2,
                  payload.AssignedOn = resultUtc,
                  payload.ScheduledDeparture = newScheduledDeparture,
                  payload.AssignedlLocationContainerId = assignedDoorId
  
  
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $editor.entity.Id, entity: payload });
          }
  
          // Set confirmation outParam and refresh editor
          $editor.toolbar.on_assign_door.control.readOnly = false;
          $editor.refresh();
          $editor.outParams.confirm = true;
  
  
  
      } catch (error) {
          $editor.toolbar.on_assign_door.control.readOnly = false;
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Appointment ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.DockAppointments.openErrorDialog('Assigning door on appointment error', 'An error occurred during the door assignment of the appointment.', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  
  
  
      }
  
  
  }
  
  }
  on_door_change(event = null) {
    return this.on_door_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_door_changeInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_door_change');
  const dockDoor = (await $datasources.DockAppointments.ds_get_dock_door_by_locationId.get({
      locationId: $editor.fields.dockDoor.control.value
      })).result;
  
  if($utils.isDefined(dockDoor)){
      const allowInbound = dockDoor.EligibleForAllocation;
      const allowOutbound = dockDoor.IsPrimaryPick;
  
      if (allowInbound){
          $editor.vars.doorType = 'Inbound'
      }
      else if (allowOutbound){
          $editor.vars.doorType = 'Outbound'
      }
      else {
          $editor.vars.doorType = 'Both'
      }
  }
  
  
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_print_clicked');
  
  //await $shell.FootPrintManager.openInfoDialog('Not yet implemented', 'The print functionality for this screen has not yet been implemented.');
  
  
  const dockAppointmentId = $editor.inParams.dockAppointmentId;
  
  $shell.Reports.opencustom_dock_appointment_reportDialog({
  dock_appointment_id:dockAppointmentId
  });
  
  
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_attachments_clicked');
  await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'DockAppointment', entityKeys: [{ name: 'Id', value: $editor.entity.Id }]});
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $editor: app_custom_dock_appointment_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_dock_appointment_editor.on_surveys_clicked');
  
  await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
      entities: [{ type: 'Appointment', ids: [$editor.entity.Id] }],
      operationContextTypes: null,
      warehouseIds: null
  }, 'modal');
  
  
  }
  //#endregion private flows
}
