import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { Orderful_messageComponent } from './Orderful.message.component';
import { Orderful_message_xmlComponent } from './Orderful.message_xml.component';
import { Orderful_orderful_runnerComponent } from './Orderful.orderful_runner.component';
import { Orderful_orderful_api_hubComponent } from './Orderful.orderful_api_hub.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { FootPrintApiManager_ShellService } from './FootPrintApiManager.shell.service';

@Injectable({ providedIn: 'root' })
export class Orderful_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
    public FootPrintApiManager: FootPrintApiManager_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Orderful: Orderful_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openmessage(inParams:{ message?: string }, replaceCurrentView?: boolean) {
    this.logger.log('Orderful', 'message');
    ShellService.openViewRequest$.next(
      {
        title: 'message',
        referenceName: 'Orderful_message',
        component: Orderful_messageComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmessageDialog(
    inParams:{ message?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Orderful', 'message');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Orderful_messageComponent,
      'message',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmessage_xml(inParams:{ message?: string }, replaceCurrentView?: boolean) {
    this.logger.log('Orderful', 'message_xml');
    ShellService.openViewRequest$.next(
      {
        title: 'message_xml',
        referenceName: 'Orderful_message_xml',
        component: Orderful_message_xmlComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmessage_xmlDialog(
    inParams:{ message?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Orderful', 'message_xml');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Orderful_message_xmlComponent,
      'message_xml',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openorderful_runner(inParams:{ title?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Runner',
        referenceName: 'Orderful_orderful_runner',
        component: Orderful_orderful_runnerComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openorderful_runnerDialog(
    inParams:{ title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Orderful_orderful_runnerComponent,
      'Runner',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openorderful_api_hub(inParams:{ integration_name?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'API Hub',
        referenceName: 'Orderful_orderful_api_hub',
        component: Orderful_orderful_api_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openorderful_api_hubDialog(
    inParams:{ integration_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Orderful_orderful_api_hubComponent,
      'API Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Orderful_message') {
      this.logger.log('Orderful', 'message');
      const title = 'message';
      const component = Orderful_messageComponent;
      const inParams:{ message?: string } = { message: null };
      if (!isNil(params.get('message'))) {
        const paramValueString = params.get('message');
        // TODO: date
        inParams.message = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Orderful_message_xml') {
      this.logger.log('Orderful', 'message_xml');
      const title = 'message_xml';
      const component = Orderful_message_xmlComponent;
      const inParams:{ message?: string } = { message: null };
      if (!isNil(params.get('message'))) {
        const paramValueString = params.get('message');
        // TODO: date
        inParams.message = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Orderful_orderful_runner') {
      const title = 'Runner';
      const component = Orderful_orderful_runnerComponent;
      const inParams:{ title?: string } = { title: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Orderful_orderful_api_hub') {
      const title = 'API Hub';
      const component = Orderful_orderful_api_hubComponent;
      const inParams:{ integration_name?: string } = { integration_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintApiManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
